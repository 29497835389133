import { useState } from 'react'

function Search ({ onSubmit }){
    const [term,setTerm] = useState('') // 3. สร้าง useState เพื่อนำค่าที่ user ป้อนใน Input มาใส่ในตัวแปร useState

    const handleOnChange = (keywords) =>{ // 4. สร้าง State System เพื่อรองรับเหตุการ์เมื่อมีการเปลี่ยนแปลงในกล่อง Input หรือเมื่อ user ป้อนข้อความ
        setTerm(keywords.target.value) //กำหนดให้เมื่อ user ป้อนข้อความ ค่าของ term จะมีค่าเท่ากับ ข้อความในกล่อง input
    }

    const handleFormSubmit = ( e )=>{ // 5. สร้าง State System เพื่อรองรับเหตุการณ์เมื่อ user กด Enter
        e.preventDefault(); //เป็นคำสั่งที่ทำให้เมื่อ user กด enter ข้อความไม่หายไป
        onSubmit(term) // เรียกใช้ props onSubmit ที่รับมาจาก App.js และนำตัวแปร term ที่มีค่าเท่ากับข้อความที่ user ป้อนเข้ามาใน input ใส่เข้าไปเพื่อส่งค่ากลับไปยังฟังก์ชั่น getValue ที่อยู่ใน App.js
    }

    return(
        <div>
        <form onSubmit ={handleFormSubmit}> {/*6. สร้าง Event System onSubmit ขึ้นมาเมื่อ user กด Enter จะเรียกใช้ State System ที่ชื่อ handleFormSubmit */}
        <div className='flex justify-center mt-[20px]'><input className='border border-1 border-black' value = {term}  onChange = { handleOnChange }/>  {/*7. กำหนดให้ Value ของ input มีค่าเท่ากับ term และสร้าง Event System onChange ขึ้นมาเมื่อ user ป้อนข้อมูลใน input จะเรียกใช้ State System ที่ชื่อ handleOnChange */}</div>
        <div className='text-center'>this is : {term}</div>
        </form>
        </div>
    )
}
export default Search