import React, { useState } from 'react'
import Search from './Component/Search'
import searchImages from './Api'
import Imagelist from './Component/Imagelist'
import './index.css';


function App (){  
    const [images,setImages] = useState([]); //สร้าง use State เพื่อนำค่าที่ได้มาจาก input ใส่ให้กับ images

    const getValue =  async (keyword) =>{    // 1. สร้างฟังก์ชั่น getValue เพื่อส่งเป็น props ไปยัง Component Search ในไฟล์ Search.js เพื่อจะเอาค่า keyword จาก input
        
        const response = await searchImages(keyword)  //เรียกใช้ Component searchImages ที่ import เข้ามา จากไฟล์ Api.js เพื่อรับค่า keyword ในฟังก์ชั่น getValue เพื่อใช้ Fetch API และเก็บค่าไว้ในตัวแปร response
       
        setImages(response) // เมื่อมีการ Fetch API นำค่าที่ได้มาใส่ให้กับ images เพื่อส่งเป็น props ไปยัง Component Imagelist ในไฟล์ Imagelist.js
    }

    return(
        <div>
       <p className='text-center font-bold text-2xl'>Search image project</p>
        <Search onSubmit = {getValue}/>  {/*2. สร้าง props ชื่อ onSubmit เพื่อส่ง props ที่เป็นฟังก์ชั่น getValue ไปให้ Component Search ในไฟล์ Search.js*/}
        <Imagelist images = {images}/>  {/* สร้าง props ชื่อ images เพื่อส่ง props ที่เป็นค่า images ที่อยู่ใน useState (มีค่าเท่ากับ response ที่ฝั่งเว็บ Unsplash ส่งกลับมาให้) ไปให้ Component Imagelist ในไฟล์ Imagelist.js*/}

        </div>
    )
}
export default App