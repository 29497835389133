import axios from 'axios';

const searchImages = async (keyword) => { //สร้าง Async ฟังก์ชั่น เพือใช้ Fetch API จากเว็บ Unsplash
   const response = await axios.get('https://api.unsplash.com/search/photos',{
        headers:{
            Authorization: 'Client-ID bQ3Fi8PwF0aJ7puFsOOVEm_VSqOADRShE3duh1rLToI'
        },
        params:{
            query: keyword  //เป็นตัวแปรที่รับค่ามาจากฟังก์ชั่น getValue ที่อยู่ใน Component App อีกทีนึง
        }
    })
   
    

    return response.data.results; //return ค่าที่ได้มาจากการ Fetch API
}


export default searchImages;