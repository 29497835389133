import Imageshow from './Imageshow'
import  './Decolate.css'
function Imagelist ({images}){ //รับ props images (images มีค่าเท่ากับ response ที่ฝั่งเว็บ Unsplash ส่งกลับมาให้) มาจาก App.js 

    const renderImage = images.map((images)=>{ // นำ props ที่ได้รับมาจาก App.js มาดึงค่า Value  โดยใช้ map **สำคัญใช้บ่อย
        return <Imageshow image = {images} key={images.id}/> // return เป็น Component Imageshow และส่งค่า props ที่ชื่อ image ไปให้ key คือค่าที่ต้องระบุ โดยจำเป็นต้องเป็นค่าที่ uniqe ไม่ซ้ำกันและไม่มีการเปลี่ยนแปลง
    })

    return(
        <div className="grid grid-cols-4 gap-y-2 justify-items-center">{renderImage}</div> // นำ renderImage มาแสดง
    )
}
export default Imagelist